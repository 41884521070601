// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
// https://firebase.google.com/docs/perf-mon/get-started-web?authuser=0
import { getPerformance } from 'firebase/performance';
import { getRemoteConfig } from "firebase/remote-config";
import { __DEV__ } from './utils';

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);
const remoteConfig = getRemoteConfig(app);
// TODO
remoteConfig.settings.minimumFetchIntervalMillis = 3600;
remoteConfig.defaultConfig = {
  "loginEnabled": false,
  "diceEnabled": __DEV__,
};

if (process.env.NODE_ENV === 'development') {
  perf.instrumentationEnabled = false;
  perf.dataCollectionEnabled = false;
}

export default app;
export {
  analytics,
  perf,
  remoteConfig
};
