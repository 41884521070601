import { LoadingOutlined } from '@ant-design/icons';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, Spin, theme } from 'antd';
import dayjs from "dayjs";
import { logEvent } from "firebase/analytics";
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.jsx';
import { analytics } from "./firebase";
import './firebase';
import client from "./graphql";
import reportWebVitals from "./reportWebVitals";
import './styles/index.css';
import { __DEV__ } from "./utils";

require('dayjs/locale/fr');

dayjs.locale('fr');
dayjs.extend(require('dayjs/plugin/localizedFormat'));

Spin.setDefaultIndicator(<LoadingOutlined />);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <ConfigProvider
    locale="fr"
    theme={{
      token: {
        colorPrimaryBg: 'black',
        colorPrimary: '#D12EA5',
        colorBgBase: 'black',
        colorLink: '#D12EA5',
        colorTextHeading: 'white',
        pageHeaderColorBack: 'white',
      },
      components: {
        Layout: {
          // darkHeaderBg: '#141414',
          triggerBg: '#141414',
          siderBg: '#141414',
          colorText: 'white'
        },
        Radio: {
          colorWhite: '#e7e7e7'
        },
        Spin: {
          colorPrimary: '#D12EA5',
        },
        Carousel: {
          dotHeight: '10px',
          colorBgContainer: 'white',
        },
        // Modal: {
        //   contentBg: 'black',
        // }
      },
      algorithm: theme.darkAlgorithm,
    }}
  >
    <Suspense fallback={<Spin spinning className="full-page-spinner" size="large" />}>
      <BrowserRouter>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </BrowserRouter>
    </Suspense>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(entry => {
  if (!__DEV__) {
    logEvent(analytics, entry.name, entry);
  }
});
