import {
  AuditOutlined,
  BankOutlined,
  BarcodeOutlined,
  BookOutlined,
  BranchesOutlined,
  CodeOutlined,
  CompassOutlined,
  ExperimentOutlined,
  FileProtectOutlined,
  FrownOutlined,
  NotificationOutlined,
  PoweroffOutlined,
  QuestionOutlined,
  SmileOutlined,
  UserOutlined,
  XOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

// todo : configure
const TUNE = 'https://vampire-cms.s3.amazonaws.com/zapsplat_horror_drone_atmos_dark_whining_eerie_sinister_45121.mp3';

const items = (context) => {
  const isMalkavian = context.currentCharacter?.clan?.data?.id === "6" || context.user?.isAdmin;
  const isNosferatu = context.currentCharacter?.clan?.data?.id === "8" || context.user?.isAdmin;
  return [
    {
      key: '/dashboard',
      label: <Link to="/dashboard">Accueil</Link>,
      icon: <BankOutlined />
    },
    {
      key: '/dashboard/personnage',
      label: <Link to="/dashboard/personnage">Mon Personnage</Link>,
      icon: <UserOutlined style={{ color: 'aqua' }} />
    },
    {
      key: '/dashboard/objectifs',
      label: <Link to="/dashboard/objectifs">Objectifs</Link>,
      icon: <BranchesOutlined style={{ color: 'mediumpurple' }} />
    },
    { type: 'divider' },
    {
      key: '/dashboard/actions',
      label: <Link to="/dashboard/actions">Actions</Link>,
      icon: <AuditOutlined />
    },
    {
      key: '/dashboard/items',
      label: <Link to="/dashboard/items">Items</Link>,
      icon: <ExperimentOutlined />
    },
    {
      key: '/dashboard/bulletin',
      label: <Link to="/dashboard/bulletin">Bulletin</Link>,
      icon: <NotificationOutlined />
    },
    {
      key: '/dashboard/carte',
      label: <Link to="/dashboard/carte">Carte</Link>,
      icon: <CompassOutlined />
    },
    {
      key: '/dashboard/politique',
      label: <Link to="/dashboard/politique">Politique</Link>,
      icon: <FileProtectOutlined />
    },
    { type: 'divider' },
    {
      key: '/scan',
      label: <Link to="/scan">Scan</Link>,
      icon: <BarcodeOutlined style={{ color: 'hotpink' }} />
    },
    // {
    //   key: 'audio',
    //   icon: <SoundOutlined />,
    //   label: (
    //     <AudioPlayer url={TUNE} />
    //   )
    // },
    isMalkavian && {
      key: '/dashboard/madness',
      label: <Link to="/dashboard/madness">Madness Network</Link>,
      icon: <XOutlined style={{ color: 'skyblue' }} />
    },
    isNosferatu && {
      key: '/dashboard/schrecknet',
      label: <Link to="/dashboard/schrecknet">SchreckNet</Link>,
      icon: <CodeOutlined style={{ color: 'limegreen' }} />
    },
    {
      key: '/cimetiere',
      label: <Link to="/cimetiere">Cimetière</Link>,
      icon: <FrownOutlined style={{ color: 'red' }} />
    },
    { type: 'divider' },
    {
      key: '/regles',
      label: <Link to="/regles">Règles</Link>,
      icon: <BookOutlined twoToneColor="#D12EA5" />
    },
    {
      key: '/faq',
      label: <Link to="/faq">FAQ</Link>,
      icon: <QuestionOutlined />
    },
    { type: 'divider' },
    {
      key: '/dashboard/profile',
      label: <Link to="/dashboard/profile">Profile</Link>,
      icon: <SmileOutlined />
    },
    {
      key: '/logout',
      label: <Link to="/logout">Déconnexion</Link>,
      icon: <PoweroffOutlined />
    },
  ];
};

export default items;
