import { useLazyQuery, useMutation } from "@apollo/client";
import { Avatar, Button, Input, List, Modal, notification, Row, Spin, Typography } from "antd";
import React, { useContext, useEffect, useState } from 'react';
import Skull from '../../assets/skull-white.png';
import DEATH_WISHES from "../../queries/deathWishes.graphql";
import WISH from "../../queries/wish.graphql";
import { AppContext } from "../AppContext";

const DeathWish = ({ id, name, }) => {
  const context = useContext(AppContext);

  const [getDeathWishes, { data: dataWishes, loading: loadingWishes }] = useLazyQuery(DEATH_WISHES);
  const [sendWish, { loading, error, data: dataWish }] = useMutation(WISH);

  const [message, setMessage] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    modalVisible && getDeathWishes({
      variables: { id }
    });
  }, [modalVisible]);

  useEffect(() => {
    getDeathWishes({ variables: { id } });
  }, []);

  useEffect(() => {
    error && notification.error({
      message: 'Erreur', description: error.message
    })
  }, [error]);

  async function onSendWish() {
    await sendWish({
      variables: {
        data: {
          from: context.currentCharacter?.id,
          to: id,
          message,
          publishedAt: new Date(),
        }
      }
    });
    await getDeathWishes({ variables: { id }, fetchPolicy: "no-cache" });
    setMessage('');
  }

  return (
    <div key={`deathWishBox-${id}`}>
      <Row justify="center" style={{ margin: '36px 0 24px' }}>
        <Typography.Link
          onClick={() => setModalVisible(true)}
          style={{ fontSize: 22 }}
        >
          Voir les Messages ({dataWishes?.deathWishes.data.length || 0})
        </Typography.Link>
      </Row>

      <Modal
        open={modalVisible}
        maskClosable
        closable
        onCancel={() => setModalVisible(false)}
        title={
          <Typography.Title level={3} style={{ textAlign: 'center' }}>
            À la mémoire de : {name}
          </Typography.Title>
        }
        footer={null}
      >
        <Row
          align="middle"
          justify="end"
          gutter={[0, 16]}
          style={{ padding: `16px 0` }}
        >
          <Input.TextArea
            size="large"
            key={`input-${id}`}
            placeholder="Laissez un message"
            value={message}
            onChange={e => setMessage(e.target.value)}
            disabled={loading}
          />
          <Button
            type="primary"
            onClick={onSendWish}
            size="small"
            loading={loading}
            disabled={loading || message.length <= 5}
          >
            Envoyer
          </Button>
        </Row>
        <Spin spinning={loadingWishes}>
          <List
            bordered
            size="large"
            dataSource={dataWishes?.deathWishes.data}
            renderItem={({ id, attributes: { message, from } }) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar src={from?.data?.attributes?.avatar?.data[0]?.attributes?.formats?.thumbnail?.url || Skull} />}
                  description={message}
                  style={{ textAlign: 'justify' }}
                />
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default DeathWish;
