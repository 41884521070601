import { Button, Col, Modal, Row } from 'antd';

import React from 'react';

function EventPage() {
  return (
    <div className="content">
      <Row gutter={8}>
        <Col span={24}>
          <Modal
            open={true}
            // open={openDiceModal}
            title="DiceOff!"
            // onOk={handleOk}
            // onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={rollTheDice}>
                Return
              </Button>,
            ]}
            style={{
              body: { height: 400 },
              wrapper: { height: 400 }
            }}
          >
            {/*<DiceView />*/}
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default EventPage;
