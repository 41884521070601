import React from "react";

const DiceContext = React.createContext({
  roll: (die, source) => {
  },
  lastRoll: {},
  result: 0,
});

export default DiceContext;
