import { ApolloClient, concat, createHttpLink, InMemoryCache } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from '@apollo/client/link/context';
import { __DEV__ } from "./utils";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BACKEND_URL
});
const cache = new InMemoryCache({
  typePolicies: {
    Character: {
      keyFields: ["slug"],
    },
    Groupe: {
      keyFields: ["name"],
    },
    Event: {
      keyFields: ["slug"],
    },
  }
});
const client = new ApolloClient({
  link: concat(authLink, httpLink),
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
});

if (__DEV__) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export default client;
