import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Collapse, Divider, notification, Result, Row, Space, Spin, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import skull from '../assets/skull-white.png';
import { AppContext } from '../components/AppContext';
import Markdown from "../components/Markdown";
import EVENT_ACTION from '../queries/event_for_actions.graphql';
import MY_ACTIONS from '../queries/myActions.graphql';

function ActionSummaryPage() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const [getMyActions, { loading, error, data }] = useLazyQuery(MY_ACTIONS);
  const [getEventAction] = useLazyQuery(EVENT_ACTION);
  const [canMakeMove, setCanMakeMove] = useState(false);

  useEffect(() => {
    error && notification.error({
      message: 'Erreur',
      description: error.message
    })
  }, [error]);

  useEffect(() => {
    if (context.currentCharacter?.id) {
      getMyActions({
        variables: { characterId: context.currentCharacter.id },
        fetchPolicy: 'network-only'
      });
    }
  }, [getMyActions, context.currentCharacter?.id]);

  useEffect(() => {
    if (context.user?.id) {
      getEventAction({
        variables: { user: context.user.id },
      }).then((res) => {
        setCanMakeMove(res.data.events?.data?.[0].attributes.acceptsActions);
      });
    }
  }, [getMyActions, context.currentCharacter?.id]);


  return (
    <div className="content">
      <Spin spinning={loading || !data}>
        {data ? (
          <>
            <Row justify="center">
              <Col>
                {canMakeMove &&
                  <Button
                    type="primary"
                    onClick={() => navigate('/dashboard/actions/new')}
                    children="Créer mes Actions"
                  />}
              </Col>
            </Row>

            <Divider style={{ marginTop: 32 }}>
              <Typography.Title level={2}>
                Actions précédentes
              </Typography.Title>
            </Divider>

            <Row>
              <Col flex={1}>
                <Row gutter={[24, 24]}>
                  {data?.actions.data?.map(({ id, attributes }, i) => {
                    const canEdit = !!attributes.event.data.attributes.closed && !!attributes.event.data.attributes.acceptsActions;
                    let moves = attributes.Moves;
                    return (
                      <Col key={id} span={24}>
                        <Card
                          title={attributes.event.data.attributes.name}
                          extra={canEdit && (
                            <Button
                              onClick={() => navigate(`/dashboard/actions/new`)}
                              shape="round"
                              type="primary"
                              size="small"
                            >
                              Edit
                            </Button>
                          )}
                        >
                          <Collapse
                            defaultActiveKey={moves[0].id}
                            items={moves.map((m, i) => ({
                                key: m.id,
                                label: `Action: ${i + 1}`,
                                children: (
                                  <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                    <Markdown>{m.description}</Markdown>
                                    <hr />
                                    <p>Réponse</p>
                                    <Markdown>{m.result}</Markdown>
                                  </Space>
                                )
                              })
                            )}
                          />
                          {attributes.goules && (
                            <>
                              <Typography.Title level={5}>Goules</Typography.Title>
                              <Markdown>
                                {attributes.goules}
                              </Markdown>
                            </>
                          )}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <Result
            icon={<img alt="skull not-found" src={skull} height={300} />}
            title="Tu n'as pas fait d'actions."
            status="info"
            className="content"
          />
        )}
      </Spin>
    </div>
  );
}

export default ActionSummaryPage;
