import { useLazyQuery } from "@apollo/client";
import { Badge, Col, Grid, Row, Typography } from "antd";
import React, { useContext } from 'react';
import CHARACTER from "../queries/character_by_id.graphql";
import { __DEV__ } from '../utils';
import { AppContext } from "./AppContext";
import { DiceButton, DiceContext } from "./Dice";

const CharacterTools = () => {
  const context = useContext(AppContext);
  const diceRoller = useContext(DiceContext);
  const diceEnabled = context?.config?.diceEnabled?.asBoolean() || __DEV__;
  const [getCharacter] = useLazyQuery(CHARACTER);
  const breakpoint = Grid.useBreakpoint();
  const isMobile = breakpoint.xs;

  return (
    <Row gutter={16} wrap justify="center" align="middle">
      {breakpoint.sm ? (
        <Row>
          {context.appliedEffects.length > 0 ? (
            <Col>
              {context.appliedEffects.map(({ id, attributes: { name, description, duration } }) => {
                let count = `${name}`;
                if (duration) {
                  count += ` (${duration})`;
                }
                return (
                  <Badge
                    key={id}
                    title={description}
                    count={count}
                    style={{ marginRight: 8, color: 'white' }}
                  />
                );
              })}
            </Col>
          ) : null}
        </Row>
      ) : null}
      {diceEnabled ? (
        <Col flex={1}>
          <Row style={{ border: '1px solid #E7E7E7', borderRadius: 4 }}>
            <Col>
              <DiceButton
                short
                type="ghost"
                from="default"
                placement="bottom"
              />
            </Col>
            {diceRoller.lastRoll ? (
              <Col style={{ paddingRight: 6 }}>
                <Row>
                  <Typography.Text style={{ fontSize: 10 }} underline type="success">
                    Résultat
                  </Typography.Text>
                </Row>
                <Row justify="center">
                  <Typography.Text type="success">
                    {diceRoller.result}
                  </Typography.Text>
                </Row>
              </Col>
            ) : null}
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};

export default CharacterTools;
