import { gql, useLazyQuery } from '@apollo/client';
import { setUserProperties } from '@firebase/analytics';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { logEvent } from 'firebase/analytics';
import { fetchAndActivate, getAll } from "firebase/remote-config";
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from './components/AppContext';
import { analytics, remoteConfig } from './firebase';
import MY_STUFF from './queries/my_stuff.graphql';
import { MyRoutes } from "./routes";
import { __DEV__ } from "./utils";

const ME = gql`query Me {me {id email username}}`;

function App() {
  const [getMe] = useLazyQuery(ME);
  const [getMyStuff] = useLazyQuery(MY_STUFF);

  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [config, setConfig] = useState({});
  const [currentCharacter, setCurrentCharacter] = useState({});
  const [currentEvent, setCurrentEvent] = useState({});
  const [appliedEffects, setAppliedEffects] = useState([]);

  // setup context
  const context = useMemo(() => {
    return ({
      ...user,
      update: setUser,
      appliedEffects,
      setAppliedEffects,
      currentCharacter,
      setCurrentCharacter,
      currentEvent,
      config,
    });
  }, [user, config, currentCharacter, currentEvent, appliedEffects]);

  useEffect(() => {
    // fetch and activate remote config
    fetchAndActivate(remoteConfig).then(() =>
      setConfig(prev => ({
        ...prev,
        ...getAll(remoteConfig)
      }))
    );

    // get users information
    getMe().then(({ data }) => {
      if (data?.me.id) {
        getMyStuff({ variables: { id: data.me.id, today: new Date() } }).then(response => {
          const { errors, error, data: myStuff } = response;
          if (errors || error) {
            notification.error({
              description: 'Vous devez vous connecter.',
              message: 'Erreur'
            });
            return navigate('/');
          }
          if (myStuff.usersPermissionsUser?.data) {
            const { attributes } = myStuff.usersPermissionsUser.data;
            const admin = attributes.role.data.attributes.name === 'Admin';

            setCurrentEvent({
              ...currentEvent,
              ...attributes.events.data[0]?.attributes,
              id: attributes.events.data[0]?.id,
            });

            setUserProperties(analytics, {
              id: data.me.id,
              userId: data.me.id,
            });

            const lastCharacter = parseInt(localStorage.getItem('currentCharacterId'), 10) || 0;
            setAppliedEffects(attributes.characters.data[lastCharacter].attributes.status_effects.data);

            // check if the character has any roll_modifiers on any relations
            // 1. get all the relations
            // TODO : MOVE THIS TO THE SERVER
            // TODO : CONVERT TO UTIL FUNCTION // re-usability: Multiple places in the app need to do this
            const character = attributes.characters.data[lastCharacter].attributes;
            const itemModifiers = character.items.data.map(i => i.attributes.roll_modifiers).filter(i => i.length);
            const flawsModifiers = character.flaws.data.map(i => i.attributes.roll_modifiers).filter(i => i.length);
            const avantagesModifiers = character.avantages.data.map(i => i.attributes.roll_modifiers).filter(i => i.length);
            const statusEffectsModifiers = character.status_effects.data.map(i => i.attributes.roll_modifiers).filter(i => i.length);
            let groupes = character.groupes.data.flatMap(({ id }) => id) || [-1];

            const allModifiers = itemModifiers
              .concat(flawsModifiers, avantagesModifiers, statusEffectsModifiers)
              .flat()
              .map(m => ({
                id: m.roll_modifier.data.id,
                name: m.roll_modifier.data.attributes.name,
                value: m.value,
              }));

            setCurrentCharacter({
              ...currentCharacter,
              id: lastCharacter || attributes.characters.data[lastCharacter || 0].id,
              ...character,
              allModifiers,
            });


            setUser(prev => ({
              ...prev,
              characters: attributes.characters.data,
              groupes,
              user: {
                ...user,
                ...data.me,
                addresse: attributes.addresse,
                allergies: attributes.allergies,
                blocked: attributes.blocked,
                fullName: attributes.fullName,
                role: attributes.role.data.attributes.name,
                isAdmin: admin,
              },
            }));
          }
        })
      }
    });
  }, [getMe, getMyStuff]);

  useEffect(() => {
    if (!__DEV__) {
      logEvent(analytics, 'page_view', {
        page_location: location.pathname,
        page_title: window.title,
        page_path: location.pathname
      });
    }
  }, [location]);

  return (
    <AppContext.Provider value={context}>
      <MyRoutes />
    </AppContext.Provider>
  );
}

export default App;
export {
  dayjs
};
