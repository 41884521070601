import { useState } from "react";
import Correspondence from '../assets/symbols/SphereCoreCorrespondence.webp';
import Entropy from '../assets/symbols/SphereCoreEntropy.webp';
import Forces from '../assets/symbols/SphereCoreForces.webp';
import Life from '../assets/symbols/SphereCoreLife.webp';
import Matter from '../assets/symbols/SphereCoreMatter.webp';
import Mind from '../assets/symbols/SphereCoreMind.webp';
import Prime from '../assets/symbols/SphereCorePrime.webp';
import Spirit from '../assets/symbols/SphereCoreSpirit.webp';
import Telos from '../assets/symbols/SphereCoreTelos.webp';
import Time from '../assets/symbols/SphereCoreTime.webp';

const Images = [
  Correspondence,
  Entropy,
  Forces,
  Life,
  Matter,
  Mind,
  Prime,
  Spirit,
  Telos,
  Time,
];
let list = [...Array(600)];

const TestPage = (props) => {
  // console.log(window.con)
  const w = window.innerWidth;
  const h = window.innerHeight;
  const [prev, setPrev] = useState(-1);

  console.log(w / 24, h / 24, (w / 24 * h / 24));

  return (
    <div style={{ backgroundColor: "white" }}>
      {list.map(i => {
        let number = Math.floor(Math.random() * 10);
        if (prev === number) {
          number = Math.floor(Math.random() * Images.length - 1);
          setPrev(number);
        }
        let image = Images[number];
        return (
          <img
            id={i}
            key={i}
            src={image}
            width={24}
            height={24}
            alt="image"
            style={{ margin: 16, cursor: 'grab' }}
          />
        )
      })}
    </div>
  );
}

export default TestPage;
