import { useLazyQuery } from '@apollo/client';
import { Col, Row, Typography } from 'antd';
import { useCallback, useContext, useState } from "react";
import AVAILABLE_ITEMS from "../queries/available_items.graphql";
import GET_ITEM from "../queries/target_item.graphql";
import { AppContext } from "./AppContext";
import DebounceSelect from "./DebounceSelect";

/**
 * Show all items accessible to the current character.
 *
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
function TargetItem({ onChange }) {
  const context = useContext(AppContext);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [getItems] = useLazyQuery(AVAILABLE_ITEMS);
  const [fetchTarget] = useLazyQuery(GET_ITEM);

  const fetchItemList = useCallback(async name => {
    if (!context.currentEvent.id || !context.currentCharacter) return [];
    const { data } = await getItems({
      variables: {
        charId: context.currentCharacter.id,
      },
    });

    const participants = data?.event?.data.attributes.participants.data;
    return participants?.flatMap(({ id, attributes }) =>
      attributes.characters.data.map(character => ({
        key: `target-${character.id}`,
        label: character.attributes.name,
        value: character.attributes.slug,
      }))
    ) || [];
  }, [getItems, context.currentEvent, context.currentCharacter]);

  return (
    <Row style={{ margin: '8px 0' }}>
      <Col flex={1}>
        <Typography.Text style={{ marginRight: 8 }}>Cible</Typography.Text>
        <DebounceSelect
          value={selectedSlug}
          placeholder="Select Target"
          fetchOptions={fetchItemList}
          debounceTimeout={500}
          onChange={async value => {
            setSelectedSlug(value);
            const response = await fetchTarget({ variables: { slug: value.value } });
            onChange({
              id: response.data.items.data[0].id,
              ...response.data.items.data[0].attributes,
            });
          }}
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
  );
}

export default TargetItem;
