import { useLazyQuery } from '@apollo/client';
import { Card, Carousel, Col, Image, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import defaultImage from '../../assets/unknown.png';
import GET_CHARACTER from '../../queries/character_by_id_public.graphql';

export function CharacterCard(
  {
    id,
    owned = false,
    showSpotify = false,
    children,
    attributes,
    loading = false,
    imageHeight = 286,
    extra,
  },
) {
  const navigate = useNavigate();
  const [loadCharacter, { data, error }] = useLazyQuery(GET_CHARACTER);
  const [characterAttributes, setCharacterAttributes] = useState(attributes);

  // if there's no attributes and there's an id, load the character
  useEffect(() => {
    if (!attributes && id) {
      loadCharacter({ variables: { id, } });
    }
  }, []);

  const {
    dead,
    avatar,
    name,
    spotify,
  } = attributes || data?.character?.data?.attributes || {
    dead: false,
    avatar: { data: [] },
    name: 'Inconnu',
    spotify: '',
  };

  return (
    <Card
      key={`character-${id}`}
      loading={loading}
      size="small"
      hoverable={owned}
      onClick={() => owned && navigate('/dashboard/personnage')}
      title={name}
      extra={
        <>
          {dead ? (<Typography.Text type="danger">Mort</Typography.Text>) : null}
          {extra}
        </>
    }
    >
      <Carousel autoplay infinite={false} adaptiveHeight>
        {avatar?.data?.length ? avatar.data?.map(({ id, attributes }) => (
          <div key={`image-${id}`}>
            <Image
              wrapperStyle={{
                width: imageHeight,
                height: imageHeight,
                // maxHeight: imageHeight,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              style={{
                width: imageHeight,
                height: imageHeight,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              src={attributes?.formats.large?.url || attributes?.formats.medium?.url || attributes?.formats.small?.url || attributes?.formats.thumbnail?.url}
              placeholder={
                <Image
                  preview={false}
                  src={attributes?.formats.thumbnail?.url || attributes?.formats.small?.url}
                />
              }
              fallback={Logo}
            />
          </div>
        )) : (
          <div>
            <Image
              wrapperStyle={{
                width: imageHeight,
                maxHeight: imageHeight,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              src={defaultImage}
              preview={false}
            />
          </div>
        )}
      </Carousel>

      {children}

      {showSpotify && spotify && (
        <Row style={{ marginTop: 16 }}>
          <Col flex={1}>
            <div dangerouslySetInnerHTML={{ __html: spotify || '' }} />
          </Col>
        </Row>
      )}
    </Card>
  );
}

export default CharacterCard;
