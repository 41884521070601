import { ArrowLeftOutlined, } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';
import { useLazyQuery } from "@apollo/client";
import { Badge, Dropdown, Grid, Layout, Menu, Typography } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Logo from "../assets/logo.png";
import Unknown from "../assets/unknown.png";
import items from '../components/MenuItems';
import CHARACTER from "../queries/character_by_id.graphql";
import { AppContext } from "./AppContext";
import CharacterTools from "./CharacterTools";
import { DiceView } from "./Dice";
import { MenuContext } from "./MenuContext";

const { Content, Sider } = Layout;

const defaultSelectedKeys = ['/dashboard'];

export default function PageLayout() {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const breakpoints = Grid.useBreakpoint();
  const [getCharacter] = useLazyQuery(CHARACTER);

  const [_, setPageTitle] = useState('');
  const [collapsed, setCollapsed] = useState(true);
  const [connected, setConnected] = useState(false);

  const [menuContext] = useState({
    collapsed,
    closeMenu: value => setCollapsed(value)
  });

  const paths = useMemo(() => location.pathname
      .split('/').slice(1)
      .map(path => ({ path, breadcrumbName: path.charAt(0).toUpperCase() + path.slice(1) })),
    [location]);

  const menuItems = useMemo(() => items(context), [context]);

  useEffect(() => {
    setConnected(!!context.user?.id)
  }, [context]);

  useEffect(() => {
    document.onkeydown = event => {
      if (event.key === '[') setCollapsed(!collapsed);
    };
    return () => {
      document.onkeydown = null;
    }
  }, [collapsed]);

  useEffect(() => {
    const t = setTimeout(() => setCollapsed(true), 1500);
    return () => clearTimeout(t);
  }, []);

  useEffect(() => {
    const breadcrumbName = paths[paths.length - 1].breadcrumbName;
    setPageTitle(breadcrumbName);
    document.title = `La Fondation - ${breadcrumbName}`;
  }, [paths]);

  const onChangeCharacter = async ({ key }) => {
    if (key === context.currentCharacter?.id) return;
    const { data: { character: { data: { id, attributes } } } } = await getCharacter({ variables: { id: key } });
    debugger
    localStorage.setItem('currentCharacterId', JSON.stringify(id));
    context.update({
      ...context,
      groupes: attributes.groupes.data.map(({ id }) => id) || [-1],
    })
    context.setCurrentCharacter({ id, ...attributes });
  };

  const characters = useMemo(() =>
    context?.characters?.map(({ id, attributes }) => ({
      label: attributes.name,
      key: id
    })) || [], [context?.characters]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {connected && (
        <Sider
          collapsible
          defaultCollapsed
          collapsed={collapsed}
          onCollapse={setCollapsed}
          breakpoint="xl"
          collapsedWidth={breakpoints['lg'] ? 80 : 0}
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <div style={{ margin: "16px 24px" }}>
            <img src={Logo} style={{ height: 32 }} alt="logo" />
          </div>

          <Menu
            defaultSelectedKeys={defaultSelectedKeys}
            selectedKeys={[location.pathname]}
            mode="inline"
            items={menuItems}
          />
        </Sider>
      )}
      <Layout>
        <DiceView>
          <PageHeader
            onBack={() => paths.length && navigate(-1)}
            className="site-page-header"
            backIcon={paths.length && <ArrowLeftOutlined />}
            title={(
              <Dropdown
                menu={{
                  items: characters,
                  onClick: onChangeCharacter,
                  selectable: true,
                  defaultSelectedKeys: [context.currentCharacter?.id],
                }}
              >
                <Typography.Title level={4}>{context.currentCharacter?.name}</Typography.Title>
              </Dropdown>
            )}
            breadcrumb={paths}
            avatar={{
              src: context.currentCharacter?.avatar?.data[0]?.attributes?.formats.thumbnail.url || Unknown,
              size: 'large',
              shape: 'circle',
              alt: context.currentCharacter?.name,
            }}
            footer={breakpoints.xs && context?.appliedEffects?.length && (
              context.appliedEffects.map(({ attributes: { name, description } }) => (
                <Badge count={name} title={description} style={{ marginRight: 8, color: 'white' }} />
              ))
            )}
            extra={!connected ? <Link to="/">Connexion</Link> : <CharacterTools />}
          />
          <Content>
            <MenuContext.Provider value={menuContext}>
              <Outlet />
            </MenuContext.Provider>
          </Content>
        </DiceView>
      </Layout>
    </Layout>
  );
}

