import { logEvent } from 'firebase/analytics';
import React from 'react';
import { analytics } from '../firebase';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {
        message: '',
        stack: ''
      },
      info: { componentStack: '' }
    };
  }

  static getDerivedStateFromError(error, info) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error, info };
  }

  componentDidCatch(error, errorInfo) {
    logEvent(analytics, 'exception', error);
    console.trace(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (this.props.fallback) {
        return this.props.fallback(this.state.error, this.state.info);
      } else {
        return (
          <div>
            <h1>Something went wrong.</h1>
            <p>{this.state.error.message}</p>
            <p>{this.state.error.stack}</p>
            <p>{this.state.info.componentStack}</p>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
