import { createContext } from 'react';

/**
 * @param {{
 * academics: number
 * alertness: number
 * animal_ken: number
 * appearance: number
 * athletics: number
 * bloodpool_max: number
 * bloodpool_perturn: number
 * bloodpool_spent: number
 * brawl: number
 * charisma: number
 * computer: number
 * crafts: number
 * dead: number
 * dexterity: number
 * dodge: number
 * drive: number
 * empathy: number
 * etiquette: number
 * expression: number
 * finance: number
 * firearms: number
 * health: number
 * humanity: number
 * intelligence: number
 * intimidation: number
 * investigation: number
 * law: number
 * leadership: number
 * linguistics: number
 * manipulation: number
 * medicine: number
 * melee: number
 * money: number
 * occult: number
 * perception: number
 * performance: number
 * politics: number
 * science: number
 * security: number
 * stamina: number
 * stealth: number
 * streetwise: number
 * strength: number
 * subterfuge: number
 * survival: number
 * willpower_max: number
 * willpower_spent: number
 * wits: number
 * }} currentCharacter
 */
export const AppContext = createContext({
  user: {
    id: '',
    email: '',
    isAdmin: false,
  },
  currentEvent: {
    id: null,
    slug: null,
    name: null,
    closed: false,
    acceptsActions: false,
  },
  currentCharacter: {
    id: '',
    slug: '',
    name: '',
    allModifiers: [],
  },
  characters: [],
  appliedEffects: [],
  update: () => {
  },
  setCurrentCharacter: (data = { id: '' }) => {
  },
  setAppliedEffects: (effects = []) => {
  },
});
AppContext.displayName = 'AppContext';
