import React from 'react';

const LorePage = () => {
  return (
    <div className="content">

    </div>
  );
};

export default LorePage;
