import { useLazyQuery } from '@apollo/client';
import { Col, Row, Typography } from 'antd';
import { useCallback, useContext, useState } from "react";
import CHARACTERS_IN_EVENT from "../queries/characters_in_event.graphql";
import TARGET_CHARACTER from "../queries/target_character.graphql";
import { AppContext } from "./AppContext";
import DebounceSelect from "./DebounceSelect";

function TargetCharacter({ onChange }) {
  const context = useContext(AppContext);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [getCharacters] = useLazyQuery(CHARACTERS_IN_EVENT);
  const [fetchTarget] = useLazyQuery(TARGET_CHARACTER);

  const fetchCharacterList = useCallback(async name => {
    if (!context.currentEvent.id || !context.currentCharacter) {
      return [];
    }
    const { data } = await getCharacters({
      variables: {
        ...context.currentEvent,
        currentCharacter: context.currentCharacter.id,
      },
    });
    return data?.event?.data.attributes.participants.data.flatMap(({ id, attributes }) =>
      attributes.characters.data.map(character => ({
        key: `target-${character.id}`,
        label: character.attributes.name,
        value: character.attributes.slug,
      }))
    ) || [];
  }, [getCharacters, context.currentEvent, context.currentCharacter]);

  return (
    <Row style={{ margin: '8px 0' }}>
      <Col flex={1}>
        <Typography.Text style={{ marginRight: 8 }}>Cible</Typography.Text>
        <DebounceSelect
          value={selectedSlug}
          placeholder="Select Target"
          fetchOptions={fetchCharacterList}
          debounceTimeout={500}
          onChange={async value => {
            setSelectedSlug(value);
            const response = await fetchTarget({ variables: { slug: value.value } });
            onChange({
              id: response.data.characters.data[0].id,
              ...response.data.characters.data[0].attributes,
            });
          }}
          style={{ width: '100%' }}
        />
      </Col>
    </Row>
  );
}

export default TargetCharacter;
