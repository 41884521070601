import DiceBox from '@3d-dice/dice-box';
import { useLazyQuery } from "@apollo/client";
import { Button, Col, Grid, Modal, notification, Row, Typography } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { CharacterCard } from "../../components/Character";
import CHARACTER_EFFECTS from "../../queries/subscriptions/characterEffects.graphql";
import { __DEV__ } from "../../utils";
import { AppContext } from "../AppContext";
import ItemCard from "../ItemCard";
import Markdown from "../Markdown";
import TargetCharacter from "../TargetCharacter";
import TargetItem from "../TargetItem";
import DiceButton from "./DiceButton";
import DiceContext from "./DiceContext";
import useRollTheDice from "./useRollTheDice";
import { diceToLabel, INITIATIVE_DICE } from "./utils";

const TEST_ROLL = {
  die: '1d20 + 1 + 1',
  from: 'Test',
  rule: 'Test TestTestTestTestTestTestTest Test',
};

export default function DiceView({ children }) {
  const context = useContext(AppContext);
  const breakpoint = Grid.useBreakpoint();
  const diceEnabled = context?.config?.diceEnabled?.asBoolean() || __DEV__;
  const divRef = React.useRef(null);

  const [target, setTarget] = useState(null);
  const [diceModalVisible, setDiceModalVisible] = useState(false);
  const [nextRoll, setNextRoll] = useState(TEST_ROLL);

  const diceRef = React.useRef();
  const [syncEffects] = useLazyQuery(CHARACTER_EFFECTS);
  const {
    result,
    lastRoll,
    rollTheDice,
  } = useRollTheDice(diceRef, diceEnabled, (
    label,
    numericLabel,
    total,
    count,
    difficulty
  ) => {
    const showNumericLabel = numericLabel !== label;
    notification.success({
      message: `Résultat du jet de dé ${total}`,
      description: (
        <>
          {label} = {total}
          <br />
          {showNumericLabel ? `${numericLabel} = ${total}` : null}
          <br />
          difficulté: {difficulty}
          <br />
          # succès: {count}
        </>
      ),
      duration: 10000,
      /*onClose: () => {
        if (diceRef.current) {
          diceRef.current.clear();
        }
      },*/
    });
  });

  useEffect(() => {
    if (!diceEnabled || !divRef.current || !!diceRef.current) return;
    try {
      const dice = new DiceBox('#dice-box', {
        assetPath: '/dice-box/',
        scale: breakpoint.xs ? 7 : 9,
        settleTimeout: 3000,
        angularDamping: 0.9,
        linearDamping: 0.9,
        // enableShadows: false,
      });
      diceRef.current = dice;
      dice.init();
    } catch (e) {
      console.error(e);
    }
  }, [context?.config]);

  useEffect(() => {
    document.onkeydown = event => {
      if (event.key === ';') {
        rollTheDice(lastRoll?.die, lastRoll?.from);
      }
    };
    return () => {
      document.onkeydown = null;
    }
  }, []);

  const roll = useCallback((die, from, rule) => {
    if (!diceEnabled) return;
    if (context.currentEvent.id) {
      setDiceModalVisible(true);
      setNextRoll({ die, from, rule });
    } else {
      rollTheDice(die, from, rule);
    }
  }, [context.currentEvent.id, diceEnabled, rollTheDice]);

  const rollDisabled = !nextRoll || !target;
  console.log('nextRoll', nextRoll, 'target', target, 'rollDisabled', rollDisabled);
  const targetType = nextRoll?.targetType || 'character';

  return (
    <DiceContext.Provider
      value={{
        roll,
        lastRoll,
        result,
        setDiceModalVisible,
      }}
    >
      {diceEnabled ? (
        <div
          id="dice-box"
          ref={divRef}
          style={{
            backgroundColor: 'transparent',
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 99999,
            pointerEvents: 'none',
          }}
        />
      ) : null}

      {children}

      <Modal
        title="Lancer de dé"
        open={diceModalVisible}
        width={breakpoint.xs ? '100%' : '80%'}
        onOk={() => {
          rollTheDice(nextRoll.die, nextRoll.from);
        }}
        onCancel={() => {
          setDiceModalVisible(false);
          setNextRoll(null);
        }}
      >
        {/*
         TODO: ItemSearch
         TODO: TargetType
         */}
        {targetType === 'character'
          ? <TargetCharacter onChange={setTarget} />
          : <TargetItem onChange={setTarget} />
        }
        <Row align="center" justify="center" gutter={[8, 8]}>
          <Col span={breakpoint.xs ? 24 : 8}>
            <CharacterCard
              id={context.currentCharacter.id}
              owned={true}
              imageHeight={breakpoint.xs ? 250 : 300}
              attributes={context.currentCharacter}
            >
              <Typography.Title level={4}>
                Abilités spéciales
              </Typography.Title>
              <Button
                size={breakpoint.xs ? 'small' : 'large'}
                onClick={() => syncEffects({ variables: { id: context.currentCharacter.id } })}
              >
                Potence (+2 Dex)
              </Button>
              <Button
                size={breakpoint.xs ? 'small' : 'large'}
                onClick={() => syncEffects({ variables: { id: context.currentCharacter.id } })}
              >
                Célérité
              </Button>
            </CharacterCard>
          </Col>
          <Col span={breakpoint.xs ? 24 : 8}>
            <Row
              align="center"
              justify="center"
              style={{ marginBottom: 8 }}
            >
              <Typography.Title level={3} style={{ textAlign: 'center' }}>
                {diceToLabel(nextRoll?.die)}
              </Typography.Title>
            </Row>
            <Row
              align="center" justify="center"
              style={{ marginBottom: 8 }}
            >
              <DiceButton
                dices={INITIATIVE_DICE}
              />
              <DiceButton
                dices={{ data: [nextRoll?.die] }}
                from={nextRoll?.from}
                type="primary"
                short
                size="large"
                onRoll={rollTheDice}
                disabled={rollDisabled}
              />
            </Row>
            <Row
              align="center" justify="center"
              style={{ marginBottom: 8 }}
            >
              <Typography.Text style={{ textAlign: 'center' }}>
                {rollDisabled ? 'Choisir une cible' : 'Cliquez pour lancer'}
              </Typography.Text>
            </Row>
            <Row align="center" justify="center" style={{ marginBottom: 8 }}>
              <Typography.Text style={{ textAlign: 'center' }}>
                {nextRoll?.from}
              </Typography.Text>
            </Row>
          </Col>
          <Col span={breakpoint.xs ? 24 : 8}>
            {targetType === 'character'
              ? <CharacterCard
                id={target?.id}
                owned={false}
                imageHeight={breakpoint.xs ? 250 : 300}
                attributes={target}
              />
              : <ItemCard
                id={target?.id}
                owned={false}
                imageHeight={breakpoint.xs ? 250 : 300}
                attributes={target}
              />
            }
          </Col>
        </Row>
        <Row align="center" justify="center">
          <Markdown>
            {nextRoll?.rule}
          </Markdown>
        </Row>
      </Modal>
    </DiceContext.Provider>
  );
}
