import { useQuery } from "@apollo/client";
import { Col, notification, Row, Spin, Typography } from 'antd';
import React, { useEffect } from 'react';
import { CharacterCard } from "../components/Character";
import DeathWish from "../components/DeathWish";
import GRAVEYARD from "../queries/graveyard.graphql";

const GraveyardPage = () => {
  const { loading, error, data } = useQuery(GRAVEYARD);

  useEffect(() => {
    error && notification.error({
      message: 'Erreur', description: error.message
    })
  }, [error]);

  if (loading) return <Spin spinning />;

  return (
    <div className="content">
      <Row justify="center" align="middle">
        <Col flex={1}>
          <Typography.Title level={1} style={{ textAlign: "center" }}>
            🪦&nbsp;R.I.P.&nbsp;🪦
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="center" gutter={[24, 24]}>
        {/*<TransitionGroup*/}
        {/*  className="character-list"*/}
        {/*  component={null}*/}
        {/*>*/}
        {/*  <CSSTransition*/}
        {/*    key={id}*/}
        {/*    timeout={500}*/}
        {/*    classNames="item"*/}
        {/*  ></CSSTransition>*/}
        {/*</TransitionGroup>*/}
        {data?.characters?.data.map(({ id, attributes }) => (
          <Col key={id}>
            <CharacterCard id={id} attributes={attributes}>
              <DeathWish id={id} {...attributes} />
            </CharacterCard>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GraveyardPage;
