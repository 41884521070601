import React from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw';

const Markdown = ({ children }) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        a: ({ node, ...props }) => (<a target="_blank" {...props} />),
        code: ({ node, ...props }) => (<code className="dice-roll" {...props} />),
        u: ({ node, ...props }) => (<span style={{ textDecoration: 'underline' }} {...props} />),
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
