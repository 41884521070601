export const DEFAULT_DICE = {
  data: [{
    id: '0',
    attributes: {
      dice: '1d10',
      label: '1d10{3}; difficulté 3',
      difficulty: 3,
      modifiers: {
        data: [],
      },
    },
  }]
};

export const INITIATIVE_DICE = {
  data: [{
    id: 'initiative',
    attributes: {
      dice: '1d10',
      difficulty: 0,
      modifiers: {
        data: [
          { attributes: { name: 'Intuition' } },
          { attributes: { name: 'Vigilance' } },
        ],
      },
    },
  }]
};

/**
 * activate a "path" in the character sheet
 * @param ability
 */
export function getAbilityComponent(ability) {
  switch (ability.name) {
    case 'Auspex - Niveau 2 : Aura Perception':
      break;
    default:
      break;
  }
}

/**
 * 1d10 + Agilité, difficulté 3
 * 3<7 => 3
 * 1d10 + Agilité = 1 +1 = 2 => 0 success
 * 1d10 + Agilité = 3 +1 = 4 => 1 success
 * 1d10 + Agilité = 10+1 = 11 => 3 success 11 < 12
 * si difficulté > 7
 * x = difficulté - 7
 * success 0 = x
 * success 1 = x + 3
 * success 2 = x + 6
 * success 3 = x + 9
 * success 5 = x + 12
 * success 6 = x + 15
 * success 7 = x + 18
 * @param result
 * @param difficulty
 * @param freeSuccesses
 * @returns {number|number}
 */
export const calculateSuccessCount = (
  result = 0,
  difficulty = 7,
  freeSuccesses = 0
) => {
  const actualDifficulty = difficulty > 7 ? difficulty - 7 : difficulty;
  const success = result - actualDifficulty;
  let successCount = success % 3 === 0 ? success / 3 : Math.floor(success / 3);
  if (success >= 0) successCount++; // add the first success to the count
  return successCount + freeSuccesses;
}

/**
 * @param {{
 * academics: number
 * alertness: number
 * animal_ken: number
 * appearance: number
 * athletics: number
 * bloodpool_max: number
 * bloodpool_perturn: number
 * bloodpool_spent: number
 * brawl: number
 * charisma: number
 * computer: number
 * crafts: number
 * dead: number
 * dexterity: number
 * dodge: number
 * drive: number
 * empathy: number
 * etiquette: number
 * expression: number
 * finance: number
 * firearms: number
 * health: number
 * humanity: number
 * intelligence: number
 * intimidation: number
 * investigation: number
 * law: number
 * leadership: number
 * linguistics: number
 * manipulation: number
 * medicine: number
 * melee: number
 * money: number
 * occult: number
 * perception: number
 * performance: number
 * politics: number
 * science: number
 * security: number
 * stamina: number
 * stealth: number
 * streetwise: number
 * strength: number
 * subterfuge: number
 * survival: number
 * willpower_max: number
 * willpower_spent: number
 * wits: number
 * }} character
 * @param modifier {string}
 */
export function modifierToCharacterValues(
  character,
  modifier,
) {
  const modifierValue = character.allModifiers
    ?.filter(m => m.name === modifier)
    ?.map(m => m.value) || [];

  const extraModifier = modifierValue.length ? modifierValue[0] : 0;
  let attributeValue = 0;
  switch (modifier) {
    case 'Armes de mêlée':
      attributeValue = character.brawl;
      break;
    case 'Force':
      attributeValue = character.strength;
      break;
    case 'Dextérité':
      attributeValue = character.dexterity;
      break;
    case 'Endurance':
      attributeValue = character.stamina;
      break;
    case 'Charisme':
      attributeValue = character.charisma;
      break;
    case 'Manipulation':
      attributeValue = character.manipulation;
      break;
    case 'Apparence':
      attributeValue = character.appearance;
      break;
    case 'Perception':
      attributeValue = character.perception;
      break;
    case 'Intelligence':
      attributeValue = character.intelligence;
      break;
    case 'Intuition':
      attributeValue = character.wits;
      break;
    case 'Vigilance':
      attributeValue = character.alertness;
      break;
    case 'Athlétisme':
      attributeValue = character.athletics;
      break;
    case 'Bagarre':
      attributeValue = character.brawl;
      break;
    case 'Esquive':
      attributeValue = character.dodge;
      break;
    case 'Empathie':
      attributeValue = character.empathy;
      break;
    case 'Expression':
      attributeValue = character.expression;
      break;
    case 'Intimidation':
      attributeValue = character.intimidation;
      break;
    case 'Leadership':
      attributeValue = character.leadership;
      break;
    case 'Streetwise':
      attributeValue = character.streetwise;
      break;
    case 'Subterfuge':
      attributeValue = character.stealth;
      break;
    case 'Connaissance des animaux':
      attributeValue = character.animal_ken;
      break;
    case 'Artisanat':
      attributeValue = character.crafts;
      break;
    case 'Conduire':
      attributeValue = character.drive;
      break;
    case 'Étiquette':
      attributeValue = character.etiquette;
      break;
    case 'Armes à feu':
      attributeValue = character.firearms;
      break;
    case 'Mêlée':
      attributeValue = character.melee;
      break;
    case 'Performance':
      attributeValue = character.performance;
      break;
    case 'Sécurité':
      attributeValue = character.security;
      break;
    case 'Furtivité':
      attributeValue = character.subterfuge;
      break;
    case 'Survie':
      attributeValue = character.survival;
      break;
    case 'Érudition':
      attributeValue = character.academics;
      break;
    case 'Informatique':
      attributeValue = character.computer;
      break;
    case 'Finances':
      attributeValue = character.finance;
      break;
    case 'Investigation':
      attributeValue = character.investigation;
      break;
    case 'Droit':
      attributeValue = character.law;
      break;
    case 'Linguistique':
      attributeValue = character.linguistics;
      break;
    case 'Médecine':
      attributeValue = character.medicine;
      break;
    case 'Occulte':
      attributeValue = character.occult;
      break;
    case 'Politique':
      attributeValue = character.politics;
      break;
    case 'Sciences':
      attributeValue = character.science;
      break;
    case 'Fortitude':
      attributeValue = getHighestLevel(character.disciplines.data.filter(d => d.attributes.name.indexOf('Fortitude') !== -1));
      break;
    default:
      attributeValue = 0;
  }
  return attributeValue + extraModifier;
}

/**
 * Generate a label from a dice
 * 1d10 + Agilité + Force
 * @param dice
 * @returns {*|string}
 */
export function diceToLabel(dice) {
  if (!dice || !dice.attributes) return;
  const { dice: d, modifiers, difficulty } = dice.attributes;
  if (!modifiers?.data?.length) return `${d} {${difficulty}}`;

  const modifiersValue = modifiers.data.map(mod => mod.attributes.name).join(' + ');
  return `${d} + ${modifiersValue} {${dice.attributes.difficulty}}`;
}

/**
 * Generate a label from a dice
 * 1d10 + 1 + 1
 * @param dice - the dice object
 * @param result - dice result value: 1d10 = [1, 10]
 * @param character - the character object
 * @returns {*|string} - the numerical label
 */
export function diceToLabelNumeric(
  dice,
  result,
  character,
) {
  if (!dice || !dice.attributes || !character) return;
  const { dice: d, modifiers, difficulty } = dice.attributes;
  if (!modifiers?.data?.length) return d;

  const modifiersValue = modifiers.data.map(mod => modifierToCharacterValues(character, mod.attributes.name)).join(' + ');
  return `${result} + ${modifiersValue} {${difficulty}}`;
}

function getHighestLevel(data) {
  return Math.max(...data.map(entry => entry.attributes.level));
}
