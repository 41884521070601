import { Card, Col, Collapse, Row, Typography } from 'antd';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { CharacterCard } from "../components/Character";
import Markdown from "../components/Markdown";

const DRIVE_LINK = "https://drive.google.com/drive/folders/1R6FJIHtDW5_97Iz-U_eiMTci4tFkRyfV?usp=sharing";

function PolitiquePage() {
  // const navigate = useNavigate();
  const [constitutionText, setConstitutionText] = useState('');
  const [rencontres, setRencontres] = useState([]);

  useEffect(() => {
    import('../data/politique/constitution.md').then(res => fetch(res.default)
      .then(response => response.text())
      .then(setConstitutionText));

    import('../data/politique/conseils/janvier_92.md').then(res => fetch(res.default)
      .then(response => response.text())
      .then(r => setRencontres([{
        key: '1',
        label: 'Janvier 92',
        children: <Markdown>{r}</Markdown>,
      }])));

    import('../data/politique/conseils/mars_92.md').then(res => fetch(res.default)
      .then(response => response.text())
      .then(r => setRencontres(prev => [...prev, {
        key: '2',
        label: 'Mars 92',
        children: <Markdown>{r}</Markdown>,
      }])));
  }, []);

  return (
    <div className="content">
      <Typography.Title level={2}>Élus</Typography.Title>
      <Row style={{ height: '100vh' }} gutter={[16, 16]}>
        <Row gutter={[16, 16]}>

          <Col flex={1}>
            <Row gutter={[16, 16]} align="center" justify="middle">
              <Col xs={24} sm={6}>
                <CharacterCard id="17" extra="Triumvirat" />
              </Col>
              <Col xs={24} sm={6}>
                <CharacterCard id="34" extra="Triumvirat" />
              </Col>
              <Col xs={24} sm={6}>
                <CharacterCard id="123" extra="Triumvirat" />
              </Col>
            </Row>
          </Col>

          <Col flex={1}>
            <Row gutter={[16, 16]} align="center" justify="middle">
              <Col xs={24} sm={6}>
                <CharacterCard id="59" extra="Notaire" />
              </Col>
              <Col xs={24} sm={6}>
                <CharacterCard id="26" extra="Gardienne du sang" />
              </Col>
            </Row>
          </Col>

          <Col flex={1}>
            <Row gutter={[16, 16]} align="center" justify="middle">
              <Col xs={24} sm={6}>
                <CharacterCard id="87" extra="Accueillante" />
              </Col>
              <Col xs={24} sm={6}>
                <CharacterCard id="31" extra="Coordonnateur des accueillants" />
              </Col>
              <Col xs={24} sm={6}>
                <CharacterCard id="14" extra="Accueillant" />
              </Col>
            </Row>
          </Col>
        </Row>

        <Typography.Title level={2}>Lois</Typography.Title>

        <Card
          title="Constitution"
          extra={
            <Link to={DRIVE_LINK}>
              Google Drive
            </Link>
          }
        >
          <Markdown>
            {constitutionText}
          </Markdown>
        </Card>
        <Row>
          <Card
            title="Rencontres"
            extra={
              <Link to={DRIVE_LINK}>
                Google Drive
              </Link>
            }
          >
            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition="right"
              items={rencontres.map(r => ({
                key: r.key,
                label: r.label,
                children: r.children,
              }))}
            />
          </Card>
        </Row>
      </Row>
    </div>
  );
}

export default PolitiquePage;
