import { useLazyQuery } from "@apollo/client";
import { Card, Col, notification, Result, Row, Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import Skull from "../assets/skull-white.png";
import { AppContext } from '../components/AppContext';
import Markdown from "../components/Markdown";
import GOALS from "../queries/goals.graphql";

const GoalsPage = () => {
  const context = useContext(AppContext);
  const [getGoals, { loading, error, data }] = useLazyQuery(GOALS);

  useEffect(() => error && notification.error({
    message: 'Erreur',
    description: error.message
  }), [error]);

  useEffect(() => {
    if (context?.currentCharacter?.id) getGoals({ variables: { id: context.currentCharacter.id } });
  }, [context]);

  if (loading) return <Spin spinning />;
  if (!data?.goals.data.length) return (
    <Result
      icon={<img alt="skull not-found" src={Skull} height={300} />}
      title="Aucun objectif"
      status="info"
      className="content"
    />
  );

  return (
    <div className="content">
      <Row justify="center" gutter={[16, 16]} wrap>
        {data?.goals.data.map(({ id, attributes }) => (
          <Col flex="1 0 25%" key={id}>
            <Card title={attributes.title}>
              <Markdown>
                {attributes.description}
              </Markdown>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GoalsPage;
