import { useLazyQuery } from '@apollo/client';
import { Card, Col, Grid, Image, notification, Pagination, Result, Row, Spin } from 'antd';
import React, { useEffect } from 'react';
import Skull from "../assets/skull-white.png";
import Markdown from "../components/Markdown";
import BULLETIN from "../queries/bulletins.graphql";

const BulletinPage = () => {
  const brk = Grid.useBreakpoint();
  const [getBulletins, { data, loading, error }] = useLazyQuery(BULLETIN);

  useEffect(() => error && notification.error({
    message: 'Erreur',
    description: error.message
  }), [error]);

  useEffect(() => {
    getBulletins({
      variables: {}
    });
  }, []);

  if (!data?.bulletins.data.length) return (
    <Result
      icon={<img alt="skull not-found" src={Skull} height={300} />}
      title="Aucune nouvelle"
      status="info"
      className="content"
    />
  );

  return (
    <div className="content">
      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          {data?.bulletins.data.map((
            { id, attributes },
            i
          ) => {
            return (
              <Col key={id} xs={24}>
                {/*<TransitionGroup className="event-list" component={null}>*/}
                <Card
                  title={attributes.title}
                  cover={
                    // TODO Carousel
                    attributes.images.data.length && (<Image
                      preview
                      src={
                        attributes.images.data[0].attributes.formats.small.url
                        || attributes.images.data[0].attributes.formats.medium.url
                        || attributes.images.data[0].attributes.formats.large.url
                      }
                      style={{ objectFit: 'cover', height: brk['xl'] ? 540 : 540 }}
                      placeholder={
                        <Image
                          preview={false}
                          src={attributes.images.data[0].attributes.formats.thumbnail.url}
                          width={200}
                        />
                      }
                      alt={attributes.name}
                    />)
                  }
                >
                  <Markdown>{attributes.text}</Markdown>
                </Card>
              </Col>
            );
          })}
        </Row>
        {data?.bulletins.data.length && (
          <Row align="center" justify="center" style={{ padding: '32px 0' }}>
            <Col>
              <Pagination
                defaultCurrent={1}
                pageSize={10}
                onChange={page => getBulletins({ variables: { page } })}
                total={data?.bulletins?.meta.pagination.total}
              />
            </Col>
          </Row>
        )}
      </Spin>
    </div>
  );
};

export default BulletinPage;
